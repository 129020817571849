<template>
  <div>
    <div class="pt-10 tw-w-full tw-grid tw-grid-cols-1 tw-gap-0 tw-gap-8">
      <v-radio-group
        @change="showModal"
        v-model="assessmentStatus"
        row
        class="mt-0 pt-0"
      >
        <v-radio
          v-for="(status, index) in statuses"
          :key="index"
          :label="status.name"
          :value="status.value"
        ></v-radio>
      </v-radio-group>
    </div>
    <div class="add--vehicle tw-mb-6">
      <v-dialog v-model="showAssessmentStatusDialog" width="400px">
        <v-card>
          <v-container class="text-center">
            <v-card-title>
              <h3 class="mx-auto primary--text">
                Confirm {{ selectedAssessmentStatusName }} selection
              </h3>
            </v-card-title>
            <v-card-text>
              <p>
                You will not be able to recover this
                <br />
                decision, are you sure you want to select
                <br />
                this vehicle as a {{ selectedAssessmentStatusName }} ?
              </p>
            </v-card-text>
            <v-card-actions>
              <div class="ma-auto pb-2">
                <v-row align="center" justify="space-around">
                  <v-btn outlined @click="closeModal" color="primary"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary"
                    class="ma-5 pa-4"
                    @click="updateAssessmentStatus"
                    :loading="loading"
                  >
                    Yes
                  </v-btn>
                </v-row>
              </div>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AssessmentStatusCheckbox',
  props: {
    bookingId: {
      required: true,
    },
  },
  data() {
    return {
      assessmentStatus: null,
      loading: false,
      showAssessmentStatusDialog: false,
      statuses: [],
    }
  },
  mounted() {
    this.getStatuses()
  },
  computed: {
    selectedAssessmentStatusName: function () {
      return this.assessmentStatus
        ? this.assessmentStatus.replaceAll('_', ' ').toUpperCase()
        : null
    },
  },
  methods: {
    showModal: function () {
      this.showAssessmentStatusDialog = true
    },
    updateAssessmentStatus() {
      axios
        .patch(
          `/motor-assessment/api/internal-assessor/update-booking-status/${this.bookingId}/${this.assessmentStatus}`
        )
        .then((response) => {
          if (response.data.success) {
            this.$store.commit('SNACKBAR', {
              state: 'success',
              message: `Vehicle selected as a ${this.selectedAssessmentStatusName}`,
              variant: 'success',
            })

            this.$router.push({ name: 'BidsSubmitted' })
          } else {
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: response.data.msg,
              variant: 'error',
            })
          }
          this.loading = false
        })
        .catch(() => {
          this.$toasted.error('An error occurred, try again', {
            duration: 3000,
          })
        })
    },
    getStatuses() {
      axios.get('/motor-assessment/api/bid/status').then((res) => {
        this.statuses = res.data.data.map((status) => ({
          name: this.getStatusName(status),
          value: status,
        }))
        this.loading = false
      })
    },
    getStatusName(status) {
      switch (status) {
        case 'CASH_IN_LIEU':
          return 'Cash in Lieu'
        case 'BELOW_EXCESS':
          return 'Below Excess'
        case 'WRITE_OFF':
          return 'Write Off'
        case 'UNDOCUMENTED':
          return 'Undocumented'
        case 'DECLINED':
          return 'Declined'
        case 'UNDER_INVESTIGATIONS':
          return 'Under Investigations'
        default:
          return status
      }
    },
    closeModal: function () {
      this.showAssessmentStatusDialog = false
      this.assessmentStatus = null
    },
  },
}
</script>
