<template>
  <v-dialog
    v-model="reportDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-form
      ref="checklistform"
      @submit.prevent="submitChecklist"
      lazy-validation
      v-model="valid"
    >
      <v-card class="c-bg-gray">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="exitDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Garage Report</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container align="center" justify="center">
          <v-row class="mx-auto py-4">
            <v-col>
              <h1>
                <router-link :to="{ name: 'AssessmentCenter' }"
                  >Assessment center</router-link
                >
                | <span class="title"> Bids Submitted</span>
              </h1>
            </v-col>
            <v-col cols="auto">
              <h3 class="title">PDF Downloads:</h3>
            </v-col>
            <v-col cols="auto">
              <v-btn
                @click="
                  downloadReport(
                    'VEHICLE_ASSESSMENT_REPORT',
                    reportParams.garageId,
                    reportParams.bookingId
                  )
                "
                class="secondary mx-4"
                :loading="assessmentReportLoading"
                >Assessment Report
                <v-icon class="mx-1">mdi-file-download-outline</v-icon>
              </v-btn>
              <v-btn
                @click="
                  downloadReport(
                    'GARAGE_BIDDING_REPORT',
                    reportParams.garageId,
                    reportParams.bookingId
                  )
                "
                class="secondary"
                :loading="garageReportLoading"
                >Garage Report
                <v-icon class="mx-1">mdi-file-download-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mx-auto" cols="12" md="12">
              <v-card
                elevation="2"
                class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10"
              >
                <div v-if="!loading">
                  <template v-if="bids.length > 0">
                    <v-tabs
                      v-model="tab"
                      class="tw-my-4 md:tw-my-10"
                      active-class="tab-active"
                      background-color="primary"
                      center-active
                      color="warning"
                      grow
                      dark
                      show-arrows
                    >
                      <v-tabs-slider
                        color="warning"
                        class="tw-p-3"
                      ></v-tabs-slider>
                      <v-tab>Assessment Report</v-tab>
                      <v-tab>
                        {{ scanObject(garageInfo, 'garage.name') }}</v-tab
                      >
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <AssessmentReport
                          :is-garage="false"
                          :booking="{
                            biddingForm: biddingTemplate,
                            ...bidInfo,
                          }"
                        />
                      </v-tab-item>
                      <v-tab-item>
                        <garage-bid-template
                          :garageInfo="garageInfo"
                          :booking="{
                            biddingForm: biddingTemplate,
                            ...bidInfo,
                          }"
                        />
                      </v-tab-item>
                    </v-tabs-items>
                  </template>
                  <div
                    v-else
                    class="
                      tw-py-4
                      md:tw-py-14
                      tw-flex tw-items-center tw-justify-center tw-flex-col
                    "
                  >
                    <img
                      :src="require('@/assets/img/assesment-center/no-car.png')"
                      class="tw-h-16 md:tw-h-28"
                      alt="no car"
                    />
                    <p
                      class="
                        tw-w-full
                        md:tw-w-1/3
                        tw-text-xs tw-p-3 tw-text-justify
                      "
                    >
                      There’s no Bids Submitted here by garages, please head to
                      the
                      <span class="c-blue-text tw-font-bold"
                        >submitted bids section</span
                      >
                    </p>
                    <v-btn
                      :to="{ name: 'BidsSubmitted' }"
                      class="tw-w-full md:tw-w-1/3"
                      color="warning"
                      >Submitted Bids</v-btn
                    >
                  </div>
                </div>
                <div
                  v-else
                  class="tw-w-full tw-flex tw-justify-center tw-py-16"
                >
                  <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import GarageBidTemplate from '@/components/assessment-center/bids-submitted/garageBidTemplate'
import AssessmentReport from '@/components/assessment-center/bids-submitted/assessmentReport'
import downloadReportsMixin from '@/mixins/downloadReportsMixin'

export default {
  name: 'GarageBidReport',
  props: ['reportDialog', 'bookingId', 'garageInfo'],
  components: { GarageBidTemplate, AssessmentReport },
  mixins: [downloadReportsMixin],

  data() {
    return {
      valid: true,
      dialog: true,
      notifications: false,
      sound: true,
      widgets: false,
      row: '',
      listComment: '',
      bids: '',
      loading: true,

      tab: null,
      biddingTemplate: null,
      bidInfo: {},
      assessmentReportLoading: false,
      garageReportLoading: false,
    }
  },
  computed: {
    reportParams: function () {
      return {
        bookingId: this.bookingId,
        garageId: this.garageInfo.garage.id,
      }
    },
  },
  mounted() {
    this.getBooking()
  },
  methods: {
    exitDialog() {
      this.$emit('changeDialog')
    },
    getBooking() {
      axios
        .get(`/motor-assessment/api/garages/bid-forms/${this.bookingId}`)
        .then((res) => {
          if (res.data.success && res.data.data === null)
            this.$router.push({ name: 'NotFound' })
          if (!res.data.success) {
            this.$router.push({ name: 'BidsSubmitted' })
          }
          const response = res.data.data
          this.bids = response.garageBiddings
          this.biddingTemplate = response.biddingTemplate
          this.bidInfo = {
            clientInfo: response.clientInfo,
            vehicleInfo: response.vehicleInfo,
            policyInfo: response.policyInfo,
          }
          this.loading = false
        })
    },
  },
}
</script>
