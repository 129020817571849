var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.reportDialog),callback:function ($$v) {_vm.reportDialog=$$v},expression:"reportDialog"}},[_c('v-form',{ref:"checklistform",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitChecklist.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"c-bg-gray"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.exitDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Garage Report")]),_c('v-spacer')],1),_c('v-container',{attrs:{"align":"center","justify":"center"}},[_c('v-row',{staticClass:"mx-auto py-4"},[_c('v-col',[_c('h1',[_c('router-link',{attrs:{"to":{ name: 'AssessmentCenter' }}},[_vm._v("Assessment center")]),_vm._v(" | "),_c('span',{staticClass:"title"},[_vm._v(" Bids Submitted")])],1)]),_c('v-col',{attrs:{"cols":"auto"}},[_c('h3',{staticClass:"title"},[_vm._v("PDF Downloads:")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"secondary mx-4",attrs:{"loading":_vm.assessmentReportLoading},on:{"click":function($event){return _vm.downloadReport(
                  'VEHICLE_ASSESSMENT_REPORT',
                  _vm.reportParams.garageId,
                  _vm.reportParams.bookingId
                )}}},[_vm._v("Assessment Report "),_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-file-download-outline")])],1),_c('v-btn',{staticClass:"secondary",attrs:{"loading":_vm.garageReportLoading},on:{"click":function($event){return _vm.downloadReport(
                  'GARAGE_BIDDING_REPORT',
                  _vm.reportParams.garageId,
                  _vm.reportParams.bookingId
                )}}},[_vm._v("Garage Report "),_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-file-download-outline")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10",attrs:{"elevation":"2"}},[(!_vm.loading)?_c('div',[(_vm.bids.length > 0)?[_c('v-tabs',{staticClass:"tw-my-4 md:tw-my-10",attrs:{"active-class":"tab-active","background-color":"primary","center-active":"","color":"warning","grow":"","dark":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{staticClass:"tw-p-3",attrs:{"color":"warning"}}),_c('v-tab',[_vm._v("Assessment Report")]),_c('v-tab',[_vm._v(" "+_vm._s(_vm.scanObject(_vm.garageInfo, 'garage.name')))])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('AssessmentReport',{attrs:{"is-garage":false,"booking":Object.assign({}, {biddingForm: _vm.biddingTemplate},
                          _vm.bidInfo)}})],1),_c('v-tab-item',[_c('garage-bid-template',{attrs:{"garageInfo":_vm.garageInfo,"booking":Object.assign({}, {biddingForm: _vm.biddingTemplate},
                          _vm.bidInfo)}})],1)],1)]:_c('div',{staticClass:"\n                    tw-py-4\n                    md:tw-py-14\n                    tw-flex tw-items-center tw-justify-center tw-flex-col\n                  "},[_c('img',{staticClass:"tw-h-16 md:tw-h-28",attrs:{"src":require('@/assets/img/assesment-center/no-car.png'),"alt":"no car"}}),_c('p',{staticClass:"\n                      tw-w-full\n                      md:tw-w-1/3\n                      tw-text-xs tw-p-3 tw-text-justify\n                    "},[_vm._v(" There’s no Bids Submitted here by garages, please head to the "),_c('span',{staticClass:"c-blue-text tw-font-bold"},[_vm._v("submitted bids section")])]),_c('v-btn',{staticClass:"tw-w-full md:tw-w-1/3",attrs:{"to":{ name: 'BidsSubmitted' },"color":"warning"}},[_vm._v("Submitted Bids")])],1)],2):_c('div',{staticClass:"tw-w-full tw-flex tw-justify-center tw-py-16"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }