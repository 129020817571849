<template>
  <v-container align="center" justify="center">
    <v-row class="mx-auto py-4">
      <v-col cols="12">
        <h1>
          <router-link :to="{ name: 'AssessmentCenter' }"
            >Assessment center</router-link
          >
          | <span class="title"> Bids Submitted</span>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mx-auto" cols="12" md="12" xl="10">
        <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
          <v-row class="mx-auto py-4">
            <v-col>
              <card-title icon="mdi-chat-processing" :is-image="false"
                >Submitted Bids</card-title
              >
              <p class="pt-10 pb-3 px-5">
                Compare the Assessment report to the bid reports to determine
                which bid is the most responsive and should be authorized for
                repairs. The authorize button is located at the bottom of the
                page, within a series of garages labeled with numbers
              </p>
            </v-col>
            <v-col cols="auto">
              <v-btn
                v-if="
                  ['CASH_IN_LIEU', 'WRITE_OFF', 'BELOW_EXCESS'].includes(
                    bidInfo.bookingStatus
                  )
                "
                @click="
                  downloadReport('VEHICLE_ASSESSMENT_REPORT', null, bookingId)
                "
                class="secondary mx-4"
                :loading="assessmentReportLoading"
                >Assessment Report
                <v-icon class="mx-1">mdi-file-download-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-title class="py-4 pl-3">
            <h2>Client Details</h2>
          </v-card-title>
          <v-divider></v-divider>
          <div
            class="
              tw-grid tw-grid-cols-1
              md:tw-grid-cols-2
              tw-gap-0 tw-gap-8
              py-3
              pl-3
            "
          >
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">Assessment No. :</label>
                <span class="c-gray-text">{{ bookingId }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">Claim No. :</label>
                <span class="c-gray-text">{{
                  scanObject(biddingTemplate, 'claimNumber')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">Insured Name :</label>
                <span class="c-gray-text">{{
                  scanObject(bidInfo, 'clientInfo.name')
                }}</span>
              </div>
            </div>
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">Date Created :</label>
                <span class="c-gray-text">{{
                  scanObject(bidInfo, 'createdAt') | formatToHuman
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">Policy No. :</label>
                <span class="c-gray-text">{{
                  scanObject(bidInfo, 'policyInfo.policyNumber')
                }}</span>
              </div>
            </div>
          </div>
          <v-card-title class="py-4 pl-3">
            <h2>Vehicle Details</h2>
          </v-card-title>
          <v-divider></v-divider>
          <div
            class="
              tw-grid tw-grid-cols-1
              md:tw-grid-cols-3
              tw-gap-0 tw-gap-8
              py-3
              pl-3
            "
          >
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold"
                  >Registration No. :</label
                >
                <span class="c-gray-text">{{
                  scanObject(bidInfo, 'vehicleInfo.registrationNumber')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">Vehicle Color :</label>
                <span class="c-gray-text">{{
                  scanObject(biddingTemplate, 'color')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">Engine No. :</label>
                <span class="c-gray-text">{{
                  scanObject(biddingTemplate, 'engineNumber')
                }}</span>
              </div>
            </div>
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">Make :</label>
                <span class="c-gray-text">{{
                  scanObject(biddingTemplate, 'make')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">YOM :</label>
                <span class="c-gray-text">{{
                  scanObject(biddingTemplate, 'yearOfManufacture')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">Chassis No. :</label>
                <span class="c-gray-text">{{
                  scanObject(biddingTemplate, 'chassisNumber')
                }}</span>
              </div>
            </div>
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">Model :</label>
                <span class="c-gray-text">{{
                  scanObject(biddingTemplate, 'model')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">Vehicle Type :</label>
                <span class="c-gray-text">{{
                  scanObject(biddingTemplate, 'bodyType')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">Mileage :</label>
                <span class="c-gray-text">{{
                  scanObject(biddingTemplate, 'mileage')
                }}</span>
              </div>
            </div>
          </div>
          <v-card-title class="py-4 pl-3">
            <h2>Assessment Details</h2>
          </v-card-title>
          <v-divider></v-divider>
          <hr class="tw-mb-2 md:tw-mb-5" />
          <div
            class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-0 tw-gap-8"
          >
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold"
                  >Assessment Company
                </label>
                <span class="c-gray-text">{{
                  scanObject(bidInfo, 'externalAssessor.name')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">PAV</label>
                <span class="c-gray-text">
                  KES
                  {{
                    scanObject(biddingTemplate, 'preAccidentValue', 0)
                      | formatCurrency
                  }}</span
                >
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">Salvage Value</label>
                <span class="c-gray-text">
                  KES
                  {{
                    scanObject(biddingTemplate, 'salvageValue', 0)
                      | formatCurrency
                  }}</span
                >
              </div>
            </div>
          </div>

          <v-card-title class="py-4 pl-3">
            <h2>Authorised Details</h2>
          </v-card-title>
          <v-divider></v-divider>
          <div
            class="
              tw-grid tw-grid-cols-1
              md:tw-grid-cols-2
              tw-gap-0 tw-gap-8
              py-3
              pl-3
            "
          >
            <div>
              <div class="tw-grid tw-grid-cols-3 tw-flex tw-items-center py-1">
                <label class="c-gray-text tw-font-bold">Authorised By :</label>
                <span class="c-gray-text">{{
                  scanObject(bidInfo, 'authorizer.fullName')
                }}</span>
                <span class="c-gray-text">{{
                  scanObject(bidInfo, 'authorizer.email')
                }}</span>
              </div>
            </div>
          </div>

          <hr class="mt-5" />
          <assessment-status-checkbox
            v-if="
              ![
                'CASH_IN_LIEU',
                'WRITE_OFF',
                'BELOW_EXCESS',
                'DECLINED',
              ].includes(bidInfo.bookingStatus)
            "
            :booking-id="bookingId"
          />
          <v-card-title class="pl-3">
            <h2>Garage Reports</h2>
          </v-card-title>
          <v-divider></v-divider>
          <div v-if="qualifiedBidsStatus && !fullReport" class="my-5">
            <v-data-table
              :headers="headers"
              :items="qualifiedBids"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`item.cost`]="{ item }">
                {{
                  scanObject(item, 'biddingForm.repairs')
                    ? vatTotal(scanObject(item, 'biddingForm.repairs', 0)) +
                      netTotal(scanObject(item, 'biddingForm.repairs', 0))
                    : 0 | formatCurrency
                }}
              </template>
              <template v-slot:[`item.biddingFormSubmittedAt`]="{ item }">
                {{ item.biddingFormSubmittedAt | formatToHuman }}
              </template>
              <template v-slot:[`item.biddingStatus`]="{ item }">
                <v-chip
                  v-if="item.biddingStatus === 'BIDDING_TEMPLATE_SENT'"
                  small
                >
                  Pending Bid</v-chip
                >
                <v-chip
                  v-else-if="item.biddingStatus === 'BIDDING_FORM_RECEIVED'"
                  color="success"
                  small
                >
                  Bid Submitted</v-chip
                >
                <v-chip v-else color="success" small> nothing</v-chip>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div class="tw-flex tw-flex-row">
                  <v-icon v-if="item.winningBid" color="success"
                    >mdi-text-box-check</v-icon
                  >
                  <v-btn text @click="openGarageReport(item)"
                    ><v-icon color="primary" class="pr-1" small>mdi-eye</v-icon>
                    View</v-btn
                  >
                </div>
              </template>
            </v-data-table>
            <garage-dialog
              :reportDialog="reportDialog"
              :bookingId="bookingId"
              :garageInfo="garageInfo"
              @changeDialog="updateDialog"
            />
          </div>
          <div v-else class="my-5">
            <v-data-table
              :headers="headers"
              :items="bids"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`item.cost`]="{ item }">
                {{
                  scanObject(item, 'biddingForm.repairs')
                    ? vatTotal(scanObject(item, 'biddingForm.repairs')) +
                      netTotal(scanObject(item, 'biddingForm.repairs'))
                    : 0 | formatCurrency
                }}
              </template>
              <template v-slot:[`item.biddingFormSubmittedAt`]="{ item }">
                {{ item.biddingFormSubmittedAt | formatToHuman }}
              </template>
              <template v-slot:[`item.biddingStatus`]="{ item }">
                <v-chip
                  v-if="item.biddingStatus === 'BIDDING_TEMPLATE_SENT'"
                  small
                >
                  Pending Bid</v-chip
                >
                <v-chip
                  v-else-if="item.biddingStatus === 'BIDDING_FORM_RECEIVED'"
                  color="success"
                  small
                >
                  Bid Submitted</v-chip
                >
                <v-chip v-else color="success" small> nothing</v-chip>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div class="tw-flex tw-flex-row">
                  <v-icon v-if="item.winningBid" color="success"
                    >mdi-text-box-check</v-icon
                  >
                  <v-btn text @click="openGarageReport(item)"
                    ><v-icon color="primary" class="pr-1" small>mdi-eye</v-icon>
                    View</v-btn
                  >
                </div>
              </template>
            </v-data-table>
            <garage-dialog
              :reportDialog="reportDialog"
              :bookingId="bookingId"
              :garageInfo="garageInfo"
              @changeDialog="updateDialog"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardTitle from '@/components/CardTitle'
import GarageDialog from '@/components/assessment-center/bids-submitted/garageDialog'
import { VAT } from '@/utils/const'

import axios from 'axios'
import { formatToHuman } from '@/utils/time'
import { formatCurrency } from '@/utils/helpers'
import AssessmentStatusCheckbox from '@/components/assessment-center/bids-submitted/AssessmentStatusCheckbox'
import DownloadReportsMixin from '@/mixins/downloadReportsMixin'

export default {
  name: 'BidDetails',
  components: { AssessmentStatusCheckbox, CardTitle, GarageDialog },
  mixins: [DownloadReportsMixin],
  data(vm) {
    return {
      reportDialog: false,
      bids: [],
      garageInfo: '',
      loading: true,
      bookingId: vm.$route.params.bookingId,
      fullReport: vm.$route.params.fullReport || false,
      tab: null,
      biddingTemplate: null,
      bidInfo: {},
      vatRate: VAT,
      headers: [
        {
          text: 'No.',
          align: 'start',
          sortable: false,
          value: 'garage.id',
        },
        { text: 'Garage', value: 'garage.name' },
        { text: 'Date', value: 'biddingFormSubmittedAt' },
        { text: 'Bid Cost', value: 'cost' },
        { text: 'Status', value: 'biddingStatus' },
        { text: 'Action', value: 'action' },
      ],
    }
  },
  mounted() {
    this.getBooking()
  },
  computed: {
    qualifiedBids() {
      return this.bids.filter((item) => item.winningBid === true)
    },
    qualifiedBidsStatus() {
      return this.bids ? this.bids.some((item) => item.winningBid) : false
    },
  },
  methods: {
    getBooking() {
      axios
        .get(`/motor-assessment/api/garages/bid-forms/${this.bookingId}`)
        .then((res) => {
          if (res.data.success && res.data.data === null) {
            this.$router.push({ name: 'NotFound' })
            this.$toasted.error('Oops!...No bid Submitted', {
              duration: 3000,
            })
          }

          if (!res.data.success) {
            this.$router.push({ name: 'BidsSubmitted' })
            this.$toasted.error('Oops!...No bid Submitted', {
              duration: 3000,
            })
          }
          const response = res.data.data
          this.bids = response.garageBiddings
          this.biddingTemplate = response.biddingTemplate
          this.bidInfo = {
            clientInfo: response.clientInfo,
            vehicleInfo: response.vehicleInfo,
            policyInfo: response.policyInfo,
            createdAt: response.bookingCreatedAt,
            externalAssessor: response.externalAssessor,
            bookingStatus: response.bookingStatus,
            authorizer: response.authorizer,
          }
          this.loading = false
        })
    },
    netTotal(item) {
      return item.reduce((result, part) => {
        return result + part.unitPrice * part.quantity
      }, 0)
    },
    vatTotal(item) {
      return item
        .filter((i) => i.vat === true)
        .reduce((res, part) => {
          return res + this.getVat(part.unitPrice * part.quantity)
        }, 0)
    },
    getVat(amount) {
      return (this.vatRate / 100) * amount
    },
    openGarageReport(item) {
      this.reportDialog = true
      this.garageInfo = item
    },
    updateDialog() {
      this.reportDialog = false
    },
  },
  filters: {
    formatCurrency,
    formatToHuman,
  },
}
</script>
